import { AxiosResponse, GenericAbortSignal } from "axios";
import { Point, Site, Stop } from "../types/trip";
import omniAxios from "./axios";

export enum StopsSortMethod {
  SiteNameAsc = "site_name",
  SiteNameDesc = "-site_name",
  TripNameAsc = "trip_name",
  TripNameDesc = "-trip_name",
  AvIdAsc = "av_id",
  AvIdDesc = "-av_id",
  StopProgressAsc = "stop_progress",
  StopProgressDesc = "-stop_progress",
  ArrivedAtAsc = "arrived_at",
  ArrivedAtDesc = "-arrived_at",
  DepartedAtAsc = "departed_at",
  DepartedAtDesc = "-departed_at",
}

export interface StopsRequestBody {
  text?: string;
  customer_ids?: string[];
  av_ids?: string[];
  site_ids?: string[];
  trip_ids?: string[];
  lane_ids?: string[];
  trip_statuses?: string[];
  event_statuses?: string[];
  sequence_statuses?: string[];
  page?: number;
  start_date?: string;
  end_date?: string;
  ordering?: string | StopsSortMethod;
  size?: number;
}

export interface StopsResponseBody {
  count: number;
  results: Stop[];
}

export const fetchStopsV2 = async (
  payload: StopsRequestBody,
  signal?: GenericAbortSignal
) => {
  return await omniAxios
    .get<
      StopsResponseBody,
      AxiosResponse<StopsResponseBody, StopsRequestBody>,
      StopsRequestBody
    >(`/v2/stops/`, {
      params: {
        ...payload,
      },
      signal: signal,
      paramsSerializer: { indexes: null },
    })
    .then((resp) => {
      const formattedResponse = resp.data.results.map((result) => {
        const updatedSiteInfo: Site = {
          id: "no-site-id-provided",
          type: result.stop_info?.location?.type,
          geometry: result.stop_info?.location?.geometry,
          properties: {
            customer_id: "no-customer-id-provided",
            name: result.stop_info?.name || "No site name provided",
            dock: result.stop_info?.dock,
            description: result.stop_info?.description,
            location: undefined,
            geofence_radius_meters: undefined,
          },
        };
        return { ...result, site: updatedSiteInfo };
      });
      return {
        count: resp.data.count,
        results: formattedResponse,
      };
    });
};
// `stop_info` is an old type and should be deleted once it is updated to `site`.
export interface StopInfo {
  name?: string;
  dock?: string;
  // description is the site address
  description?: string;
  location?: {
    type?: string;
    geometry?: {
      type: string;
      coordinates: [Point[]];
    };
    properties: {
      name?: string;
      // duplicate of description
      address?: string;
    };
  };
}
