import { useEffect, useRef, useState } from "react";
import StyledButton from "../../components/common/button/StyledButton";
import StyledTabs, { TabType } from "../../components/common/tabs/StyledTabs";
import { allColors } from "../../util/colors";
import classNames from "classnames";
import {
  useSmBreakpoint,
  useMdBreakpoint,
  useLgBreakpoint,
} from "../../util/breakpoints";
import { Stop, Trip } from "../../types/trip";
import StyledToggleButtonGroup from "../../components/common/toggle-button/StyledToggleButtonGroup";
import GridIcon from "../../components/common/svg/Grid";
import TableIcon from "../../components/common/svg/Table";
import TripCard from "../../components/trips/trip-card/TripCard";
import SortArrowsIcon from "../../components/common/svg/SortArrows";
import StopCard from "../../components/stops/stop-card/StopCard";
import TripsTable from "../../components/trips/trips-table/TripsTable";
import NoResults from "../../components/common/no-results/NoResults";
import StopsTable from "../../components/stops/stops-table/StopsTable";
import { dayjs, getTimezoneText } from "../../util/date-util";
import { useUserContext } from "../../context/UserContext";
import StyledPagination from "../../components/common/pagination/StyledPagination";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AutocompleteItem } from "../../components/form/autocomplete/types";
import TripStatusFilter, {
  tripStatusOptions,
} from "../../components/form/autocomplete/trip-status-filter/TripStatusFilter";
import { dayOptions } from "../../components/form/autocomplete/day-filter/DayFilter";
import {
  checkIsStaff,
  getNewMultiAutocompleteValues,
  useAsyncEffect,
} from "../../util/util";
import InfoCircleIcon from "../../components/common/svg/InfoCircle";
import StyledTooltip from "../../components/common/tooltip/StyledTooltip";
import FilterLinesIcon from "../../components/common/svg/FilterLines";
import { useOverlayContext } from "../../context/OverlayContext";
import GlobalFilterModal from "./GlobalFilterModal";
import React from "react";
import { timelinessOptions } from "../../components/form/autocomplete/timeliness-filter/TimelinessFilter";
import StopStatusFilter, {
  stopStatusOptions,
} from "../../components/form/autocomplete/stop-status-filter/StopStatusFilter";
import TripsFilterModal, { tripsFilters } from "./TripsFilterModal";
import { FilterControlValues } from "../../components/filter-control/types";
import StopsFilterModal, { stopsFilters } from "./StopsFilterModal";
import MegaSearch from "../../components/mega-search/MegaSearch";
import {
  MegaSearchResultItem,
  MegaSearchResultItemType,
} from "../../components/mega-search/types";
import LoadingSpinner from "../../components/common/loading-spinner/LoadingSpinner";
import {
  TripsRequestBody,
  TripsSortMethod,
  fetchTripsV2,
} from "../../api/trip";
import {
  StopsRequestBody,
  StopsSortMethod,
  fetchStopsV2,
} from "../../api/stop";
import TripTimelineDrawer from "../../components/trips/trip-timeline/trip-timeline-drawer/TripTimelineDrawer";
import { POLLING_SPEED } from "../../util/constants";
import { fetchUserInfo, updateUserInfo } from "../../api/auth";
import { CustomerSortMethods, fetchCustomers } from "../../api/customer";
import { Customer } from "../../types/customer.type";
import SearchSmIcon from "../../components/common/svg/SearchSm";
import StyledInput from "../../components/form/input/StyledInput";
import CloseCircleIcon from "../../components/common/svg/CloseCircle";
import { SvgType } from "../../components/common/svg/types";
import { useDebounce } from "../../util/debounce";

export enum LiveTrackingTab {
  Trips = "trips",
  Stops = "stops",
}
export type LiveTrackingPageProps = {
  classes?: string;
};

export default function LiveTrackingPage(props: LiveTrackingPageProps) {
  const { classes }: LiveTrackingPageProps = props;

  const { userInfo, setUserInfo } = useUserContext();
  const { isOverlayOpen, setIsOverlayOpen, setOverlayContent } =
    useOverlayContext();

  const navigate = useNavigate();
  const [filterParams, setFilterParams] = useSearchParams({ day: "today" });
  const { tab } = useParams();

  const isSm = useSmBreakpoint();
  const isMd = useMdBreakpoint();
  const isLg = useLgBreakpoint();
  const isAdmin = checkIsStaff(userInfo?.admin_user);

  const [trips, setTrips] = useState<Trip[]>([]);
  const [tripsTotalCount, setTripsTotalCount] = useState<number | undefined>(
    undefined
  );
  const [stops, setStops] = useState<Stop[]>([]);
  const [stopsTotalCount, setStopsTotalCount] = useState<number | undefined>(
    undefined
  );
  const [currentViewType, setCurrentViewType] = useState<string | undefined>(
    undefined
  );
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isSearchSticky, setIsSearchSticky] = useState<boolean>(false);

  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(10);

  const [dayFilterValue, setDayFilterValue] = useState<AutocompleteItem | null>(
    null
  );
  const [startDateFilter, setStartDateFilter] = useState<string | null>(null);
  const [endDateFilter, setEndDateFilter] = useState<string | null>(null);
  const [
    hasFinishedSettingInitialDateValues,
    setHasFinishedSettingInitialDateValues,
  ] = useState<boolean>(false);
  const [tripStatusFilterValues, setTripStatusFilterValues] = useState<
    AutocompleteItem[]
  >([]);
  const [tripTimelinessFilterValues, setTripTimelinessFilterValues] = useState<
    AutocompleteItem[]
  >([]);
  const [stopStatusFilterValues, setStopStatusFilterValues] = useState<
    AutocompleteItem[]
  >([]);
  const [stopTimelinessFilterValues, setStopTimelinessFilterValues] = useState<
    AutocompleteItem[]
  >([]);
  const [customerFilterValues, setCustomerFilterValues] = useState<
    AutocompleteItem[]
  >([]);
  const [isGlobalFiltersModalOpen, setIsGlobalFiltersModalOpen] =
    useState<boolean>(false);
  const [isTripsFiltersModalOpen, setIsTripsFiltersModalOpen] =
    useState<boolean>(false);
  const [isStopsFiltersModalOpen, setIsStopsFiltersModalOpen] =
    useState<boolean>(false);
  const [searchAutocompleteValue, setSearchAutocompleteValue] =
    useState<MegaSearchResultItem | null>(null);
  // searchInputValue & debouncedSearchInputValue are only for simple text search.
  // TODO: Once Autocomplete Search APIs are finished, remove these useState variables
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [debouncedSearchInputValue, setDebouncedSearchInputValue] =
    useState<string>("");
  const [isLoadingNewResults, setIsLoadingNewResults] =
    useState<boolean>(false);
  const [isRefreshingResults, setIsRefreshingResults] =
    useState<boolean>(false);
  const [hasLoadedInitialItems, setHasLoadedInitialItems] =
    useState<boolean>(false);
  const [abortController, setAbortController] = useState<AbortController>(
    new AbortController()
  );
  const [hasFetchedAdditionalUserInfo, setHasFetchedAdditionalUserInfo] =
    useState<boolean>(false);
  // Sort methods states
  const defaultTripSort = TripsSortMethod.TripNameAsc;
  const [tripsSortMethod, setTripsSortMethod] =
    useState<string>(defaultTripSort);
  const defaultStopSort = StopsSortMethod.SiteNameDesc;
  const [stopsSortMethod, setStopsSortMethod] =
    useState<string>(defaultStopSort);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isChangingCustomers, setIsChangingCustomers] =
    useState<boolean>(false);
  const [isDateHighlightActive, setIsDateHighlightActive] =
    useState<boolean>(false);

  // When true, MegaSearch component is used with SearchAutocomplete endpoints.
  const isAdvancedSearch = false;

  const searchContainerRef = useRef<HTMLDivElement | null>(null);
  const globalFilterControlRef = useRef<HTMLDivElement | null>(null);
  const globalFilterButtonRef = useRef<HTMLDivElement | null>(null);

  const noResults =
    (tab === LiveTrackingTab.Trips &&
      (!tripsTotalCount || tripsTotalCount === 0)) ||
    (tab === LiveTrackingTab.Stops &&
      (!stopsTotalCount || stopsTotalCount === 0));

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  // Parses the url parameters and sets the filters on the page. Only done on page load
  const setFiltersByQueryParams = () => {
    const getSingle = (param: string, options: AutocompleteItem[]) => {
      const paramValue = filterParams.get(param);
      return options.find((option) => option.value === paramValue);
    };
    const getMulti = (
      param: string,
      options: AutocompleteItem[],
      toUpperCase?: boolean
    ) => {
      const paramValues = filterParams.getAll(param);
      const allParamValues =
        paramValues[0] && toUpperCase
          ? paramValues[0].toUpperCase().split(",")
          : paramValues[0] && !toUpperCase
            ? paramValues[0].split(",")
            : undefined;
      if (allParamValues) {
        return options.filter((option) => {
          return allParamValues.includes(option.value);
        });
      } else {
        return [];
      }
    };
    const dayValues = getSingle("day", dayOptions);
    const tripStatusValues = getMulti("trip_status", tripStatusOptions, true);
    const tripTimelinessValues = getMulti(
      "trip_timeliness",
      timelinessOptions,
      true
    );
    const stopStatusValues = getMulti("stop_status", stopStatusOptions, true);
    const stopTimelinessValues = getMulti(
      "stop_timeliness",
      timelinessOptions,
      true
    );
    const customerOptions: AutocompleteItem[] = customers.map((customer) => {
      return {
        label: customer.name,
        value: customer.id,
        data: customer,
      };
    });
    // Will only be setting customer url params if user supports multiple customers (i.e. admin users)
    if (isAdmin) {
      const customerFilterValues = getMulti("customers", customerOptions);
      setCustomerFilterValues(customerFilterValues);
    }

    dayValues && setDayFilterValue(dayValues);
    setTripStatusFilterValues(tripStatusValues);
    setTripTimelinessFilterValues(tripTimelinessValues);
    setStopStatusFilterValues(stopStatusValues);
    setStopTimelinessFilterValues(stopTimelinessValues);

    // trips sort by
    const tripsSortByValue = filterParams.get("trips_sort");
    tripsSortByValue && setTripsSortMethod(tripsSortByValue);

    // stops sort by
    const stopsSortByValue = filterParams.get("stops_sort");
    stopsSortByValue && setStopsSortMethod(stopsSortByValue);

    // text search
    const textSearchValue = filterParams.get("text");
    if (textSearchValue) {
      if (isAdvancedSearch) {
        setSearchAutocompleteValue({
          name: textSearchValue,
          type: MegaSearchResultItemType.Text,
        });
      } else {
        setSearchInputValue(textSearchValue);
        setDebouncedSearchInputValue(textSearchValue);
      }
    }
  };

  // Serializes the filter values and applies them to the url parameters
  const setQueryParamsByFilters = () => {
    const updateSingle = (param: string, item: AutocompleteItem | null) => {
      if (item) {
        filterParams.set(param, item.value);
      } else {
        filterParams.delete(param);
      }
    };
    const updateMulti = (
      param: string,
      items: AutocompleteItem[],
      toLowerCase?: boolean
    ) => {
      const allItemValues = items.map((item) =>
        toLowerCase ? item.value.toLowerCase() : item.value
      );
      if (allItemValues.length > 0) {
        filterParams.set(param, allItemValues.join(","));
      } else {
        filterParams.delete(param);
      }
    };

    updateSingle("day", dayFilterValue);
    updateMulti("trip_status", tripStatusFilterValues, true);
    updateMulti("trip_timeliness", tripTimelinessFilterValues, true);
    updateMulti("stop_status", stopStatusFilterValues, true);
    updateMulti("stop_timeliness", stopTimelinessFilterValues, true);
    // Only add customer query params if user isn't restricted to single customer (i.e. admin users)
    if (isAdmin) {
      updateMulti("customer", customerFilterValues, true);
    }

    // Update trip sort method
    if (tripsSortMethod === defaultTripSort) {
      filterParams.delete("trips_sort");
    } else {
      filterParams.set("trips_sort", tripsSortMethod);
    }
    // Update stop sort method
    if (stopsSortMethod === defaultStopSort) {
      filterParams.delete("stops_sort");
    } else {
      filterParams.set("stops_sort", stopsSortMethod);
    }
    // Update text search
    if (isAdvancedSearch) {
      if (searchAutocompleteValue) {
        filterParams.set("text", searchAutocompleteValue.name);
      } else {
        filterParams.delete("text");
      }
    } else {
      if (debouncedSearchInputValue) {
        filterParams.set("text", debouncedSearchInputValue);
      } else {
        filterParams.delete("text");
      }
    }

    setFilterParams(filterParams);
  };

  const changeTab = (tab: LiveTrackingTab) => {
    navigate({
      pathname: `/live-tracking/${tab}`,
      search: `${filterParams}`,
    });
  };

  useEffect(() => {
    // Set inital loading spinner
    setIsLoadingNewResults(true);

    // Parse url params and set filters
    setFiltersByQueryParams();

    // TODO: Actually set number of pages
    // setPaginationCount(10);

    // check if user has a table/card preference. If so, set it
    const storedViewPreference = localStorage.getItem("viewPreference");
    if (!isMd || !storedViewPreference) {
      setCurrentViewType("cards");
    } else {
      setCurrentViewType(storedViewPreference);
    }

    // Closes global filter modal on desktop when outside click is pressed
    const handleOutsideGlobalFiltersClick = (e: any) => {
      if (
        !globalFilterControlRef?.current?.contains(e.target) &&
        !globalFilterControlRef?.current?.contains(document.activeElement) &&
        !globalFilterButtonRef?.current?.contains(e.target)
      ) {
        setIsGlobalFiltersModalOpen(false);
      }
    };

    const timeoutId = setTimeout(() => {
      document.addEventListener(
        "click",
        handleOutsideGlobalFiltersClick,
        false
      );
    }, 0);

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      abortController.abort();

      clearTimeout(timeoutId);
      document.removeEventListener(
        "click",
        handleOutsideGlobalFiltersClick,
        false
      );
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch list of all accessible customers to the user
  useAsyncEffect(async () => {
    const customersResp = await fetchCustomers(
      undefined,
      undefined,
      undefined,
      CustomerSortMethods.Name
    );
    setCustomers(customersResp.results);
  }, []);

  // fetches additional info such as name, customer, timezone, ect.
  const getAdditionalUserInfo = async () => {
    setHasFetchedAdditionalUserInfo(true);
    await fetchUserInfo()
      .then((resp) => {
        setUserInfo({
          ...userInfo,
          ...resp,
        });
      })
      .catch(() => {
        console.error(
          "Something went wrong when fetching additional user info."
        );
      });
  };

  useEffect(() => {
    // If there's no saved name, fetch user info and set it.
    if (userInfo && !userInfo?.first_name && !hasFetchedAdditionalUserInfo) {
      getAdditionalUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    const tripId = filterParams.get("view_trip");
    if (tripId && !isOverlayOpen) {
      openTripDrawer(undefined, tripId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  const openTripDrawer = async (trip?: Trip, tripId?: string) => {
    if (!trip && !tripId) {
      console.error("No trip or id provided.");
    } else if (!trip && tripId) {
      // If only a trip id is provided, fetch the trip and diplay it
      filterParams.set("view_trip", tripId);
      setIsOverlayOpen(true);
      setOverlayContent(
        <TripTimelineDrawer
          tripId={tripId}
          onClose={() => {
            setIsOverlayOpen(false);
            filterParams.delete("view_trip");
            setFilterParams(filterParams);
          }}
        />
      );
    } else if (trip) {
      filterParams.set("view_trip", trip.id);
      setIsOverlayOpen(true);
      setOverlayContent(
        <TripTimelineDrawer
          trip={trip}
          onClose={() => {
            setIsOverlayOpen(false);
            filterParams.delete("view_trip");
            setFilterParams(filterParams);
          }}
        />
      );
    }
    // Add the trip id to the url with "view_trip" param and open overlay
    setFilterParams(filterParams);
  };

  useEffect(() => {
    if (currentViewType) {
      localStorage.setItem("viewPreference", currentViewType);
    }
  }, [currentViewType]);

  useEffect(() => {
    // on filter change, serialize filters and set query params
    setQueryParamsByFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dayFilterValue,
    customerFilterValues,
    tripStatusFilterValues,
    tripTimelinessFilterValues,
    tripsSortMethod,
    stopStatusFilterValues,
    stopTimelinessFilterValues,
    stopsSortMethod,
    searchAutocompleteValue,
    debouncedSearchInputValue,
  ]);

  useEffect(() => {
    // if tab is not a valid value, reset url to go to default /trips
    if (tab !== LiveTrackingTab.Trips && tab !== LiveTrackingTab.Stops) {
      changeTab(LiveTrackingTab.Trips);
    }
    // reset filters/page
    setPaginationPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (!isMd) {
      setCurrentViewType("cards");
    }
    if (isMd && isTripsFiltersModalOpen) {
      setIsOverlayOpen(false);
      setIsTripsFiltersModalOpen(false);
    }
    if (isMd && isStopsFiltersModalOpen) {
      setIsOverlayOpen(false);
      setIsStopsFiltersModalOpen(false);
    }
    if (isMd && isGlobalFiltersModalOpen) {
      setIsOverlayOpen(false);
      setIsGlobalFiltersModalOpen(false);
    }
    if (!isMd && isGlobalFiltersModalOpen) {
      setIsGlobalFiltersModalOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMd]);

  useEffect(() => {
    const topSectionYPosition =
      searchContainerRef &&
      searchContainerRef.current &&
      // top y position of top section
      searchContainerRef.current.getBoundingClientRect().y +
        // number of pixels scrolled
        window.scrollY;
    if (!isSm && topSectionYPosition && scrollPosition > topSectionYPosition) {
      setIsSearchSticky(true);
    } else {
      setIsSearchSticky(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSm, scrollPosition]);

  useEffect(() => {
    // This value creates datetime value at midnight in the user's browser timezone, based on what day it is in the provided timezone (userInfo.timezone.)
    // TODO: This is not right! Change this based on expected date filter logic.
    const RealTimeDateTime = dayjs(
      new Date(
        new Intl.DateTimeFormat("en-US", {
          timeZone: (userInfo && userInfo.timezone) || undefined,
          timeZoneName: "short",
        })
          .format()
          .split(",", 1)[0]
      )
    ).toString();

    const yesterday = dayjs(RealTimeDateTime).subtract(1, "day");
    const today = dayjs(RealTimeDateTime);
    const tomorrow = dayjs(RealTimeDateTime).add(1, "day");
    const dayAfterTomorrow = dayjs(RealTimeDateTime).add(2, "day");

    // "Today" tab
    if (dayFilterValue?.value === "today") {
      setStartDateFilter(today.format());
      setEndDateFilter(tomorrow.format());
    }
    // "Tomorrow" tab
    else if (dayFilterValue?.value === "tomorrow") {
      setStartDateFilter(tomorrow.format());
      setEndDateFilter(dayAfterTomorrow.format());
    }
    // "Yesterday" tab
    else if (dayFilterValue?.value === "yesterday") {
      setStartDateFilter(yesterday.format());
      setEndDateFilter(today.format());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayFilterValue]);

  useEffect(() => {
    if (
      startDateFilter &&
      endDateFilter &&
      !hasFinishedSettingInitialDateValues
    ) {
      // Used to ensure on load, start and end date values are set before fetching trips or stops
      setHasFinishedSettingInitialDateValues(true);
    }
    if (hasFinishedSettingInitialDateValues) {
      triggerFlashHighlight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateFilter, endDateFilter]);

  useEffect(() => {
    if (isLoadingNewResults || isRefreshingResults || !hasLoadedInitialItems) {
      // If a request has already been made, cancel it and reset the abortController
      abortController.abort();
      setAbortController(new AbortController());
      setIsRefreshingResults(false);
    } else {
      // Otherwise start the loading animation
      setIsLoadingNewResults(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startDateFilter,
    tripStatusFilterValues,
    tripTimelinessFilterValues,
    tripsSortMethod,
    stopsSortMethod,
    stopStatusFilterValues,
    stopTimelinessFilterValues,
    tab,
    paginationPage,
    searchAutocompleteValue,
    debouncedSearchInputValue,
    customerFilterValues,
  ]);

  // Any time we start fetching new results or refresh current results, set a timeout to refresh. Timeout resets if either of those change.
  useEffect(() => {
    if (isLoadingNewResults) {
      abortController.abort();
      setAbortController(new AbortController());
    }
    const refreshInterval = setTimeout(() => {
      setIsRefreshingResults(true);
      if (tab === LiveTrackingTab.Trips) {
        fetchTrips();
      }
      if (tab === LiveTrackingTab.Stops) {
        fetchStops();
      }
    }, POLLING_SPEED);

    // Clear timeout when unmounting the component
    return () => clearTimeout(refreshInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingNewResults, trips]);

  useEffect(() => {
    if (abortController.signal.aborted) {
      // If the the previous request was cancelled, reset the abortController and rerun the useEffect
      setAbortController(new AbortController());
    } else if (hasFinishedSettingInitialDateValues) {
      setIsLoadingNewResults(true);
      if (tab === LiveTrackingTab.Trips) {
        fetchTrips();
      }
      if (tab === LiveTrackingTab.Stops) {
        fetchStops();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abortController, hasFinishedSettingInitialDateValues]);

  const fetchTrips = async () => {
    const params: TripsRequestBody = {
      text: isAdvancedSearch
        ? searchAutocompleteValue?.name
        : debouncedSearchInputValue,
      customer_ids: isAdmin
        ? customerFilterValues.map((item) => item.value.toLowerCase())
        : undefined,
      av_ids: undefined,
      external_ids: undefined,
      page: paginationPage,
      statuses: tripStatusFilterValues.map((item) => item.value.toLowerCase()),
      start_date: startDateFilter || undefined,
      end_date: endDateFilter || undefined,
      ordering: tripsSortMethod,
      size: itemsPerPage,
    };

    const signal = abortController.signal;
    await fetchTripsV2(params, signal)
      .then((resp) => {
        if (!hasLoadedInitialItems) {
          setHasLoadedInitialItems(true);
        }
        setTripsTotalCount(resp.count);
        setPaginationCount(Math.ceil(resp.count / itemsPerPage));
        setTrips(resp.results);
        setIsLoadingNewResults(false);
        setIsRefreshingResults(false);
      })
      .catch((error) => {
        // Don't end "loading" state or send error if the request was manually cancelled
        if (error.name !== "CanceledError") {
          console.error(error);
          setIsLoadingNewResults(false);
          setIsRefreshingResults(false);
        }
      });
  };
  const fetchStops = async () => {
    const params: StopsRequestBody = {
      text: isAdvancedSearch
        ? searchAutocompleteValue?.name
        : debouncedSearchInputValue,
      customer_ids: isAdmin
        ? customerFilterValues.map((item) => item.value.toLowerCase())
        : undefined,
      av_ids: undefined,
      site_ids: undefined,
      trip_ids: undefined,
      lane_ids: undefined,
      trip_statuses: undefined,
      event_statuses: stopStatusFilterValues.map((item) =>
        item.value.toLowerCase()
      ),
      sequence_statuses: undefined,
      page: paginationPage,
      start_date: startDateFilter || undefined,
      end_date: endDateFilter || undefined,
      ordering: stopsSortMethod,
      size: itemsPerPage,
    };

    const signal = abortController.signal;
    await fetchStopsV2(params, signal)
      .then((resp) => {
        if (!hasLoadedInitialItems) {
          setHasLoadedInitialItems(true);
        }
        setStopsTotalCount(resp.count);
        setPaginationCount(Math.ceil(resp.count / itemsPerPage));
        setStops(resp.results);
        setIsLoadingNewResults(false);
        setIsRefreshingResults(false);
      })
      .catch((error) => {
        // Don't end "loading" state or send error if the request was manually cancelled
        if (error.name !== "CanceledError") {
          console.error(error);
          setIsLoadingNewResults(false);
          setIsRefreshingResults(false);
        }
      });
  };

  const DateInfo = () => {
    const currentDayBasedOnFilter = startDateFilter
      ? dayjs(startDateFilter)
      : dayjs(new Date());
    const fullDateString = currentDayBasedOnFilter
      .tz(userInfo?.timezone || undefined)
      .format(isLg ? "dddd, MMMM D, YYYY" : "MMMM D, YYYY");
    const timezoneText = getTimezoneText(userInfo?.timezone || undefined);
    return (
      <div
        className={classNames("flex items-center -ml-1 px-1 rounded-sm", {
          "flash-highlight": isDateHighlightActive,
        })}
      >
        <p className="text-sm sm:text-md md:text-lg font-medium text-gray-600">
          {fullDateString}
        </p>
        <div className="live-tracking__info-divider"></div>
        <StyledTooltip
          header={`This page shows trips and stops that start on ${fullDateString} in ${timezoneText} timezone.`}
          placement={"top"}
        >
          <div className="flex items-center">
            <span className="text-xs text-gray-500 font-medium pt-1">
              {timezoneText}
            </span>
            <InfoCircleIcon
              width="17"
              height="17"
              stroke={allColors.gray[500]}
              classes="ml-1"
            />
          </div>
        </StyledTooltip>
      </div>
    );
  };
  // Used to highlight date text when changing date filter
  const triggerFlashHighlight = () => {
    if (!isDateHighlightActive) {
      setIsDateHighlightActive(true);
      setTimeout(() => {
        setIsDateHighlightActive(false);
      }, 2000);
    } else {
      // toggle active class off and then back on
      setIsDateHighlightActive(false);
      setTimeout(() => {
        setIsDateHighlightActive(true);
      }, 5);
    }
  };
  const AutocompleteSearch = () => {
    return (
      <MegaSearch
        initialTextValue={searchAutocompleteValue?.name}
        containerClasses="live-tracking__mega-search-container"
        setSelectedValue={(newValue) => setSearchAutocompleteValue(newValue)}
      />
    );
  };
  const debouncedSearchInput = useDebounce(() => {
    setDebouncedSearchInputValue(searchInputValue);
  }, 500);
  useEffect(() => {
    debouncedSearchInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputValue]);
  // TODO: Once APIs are finished for Autocomplete Search, remove this simple search component.
  const SimpleSearch = () => {
    return (
      <div className="live-tracking__mega-search-container">
        <StyledInput
          startIcon={<SearchSmIcon />}
          placeholder="Search by AV#, Lane ID, Trip ID or Site"
          extraClasses={classNames("mega-search__input")}
          hideFocusRing
          value={searchInputValue}
          onChange={(e) => {
            setSearchInputValue(e.target.value);
          }}
          // onKeyDown={(e) => handleInputKeyDown(e)}
          endIcon={
            searchInputValue.length > 0 ? (
              <StyledButton
                color="secondary"
                size="sm"
                textOnly
                rounded
                iconOnly
                onClick={() => {
                  setSearchInputValue("");
                  setDebouncedSearchInputValue("");
                }}
                classes="mega-search__clear-button"
              >
                <CloseCircleIcon
                  type={SvgType.Line}
                  // Fake hide the clear icon when there isn't a search value
                  width={searchInputValue ? "20" : "0"}
                  height={searchInputValue ? "20" : "0"}
                  stroke={
                    searchInputValue ? allColors.gray[500] : "transparent"
                  }
                />
              </StyledButton>
            ) : undefined
          }
        />
      </div>
    );
  };
  // When a non-admin user switches customer or "deployment" in the global filter modal, update their assigned customer and set new userInfo
  const handleChangeCustomer = async (customerId: string) => {
    setIsChangingCustomers(true);
    await updateUserInfo({
      customer_id: customerId,
    })
      .then(() => {
        getAdditionalUserInfo();
        const currentCustomer = customers.find(
          (customer) => customer.id === userInfo?.customer_id
        );
        if (currentCustomer) {
          const currentCustomerOption: AutocompleteItem = {
            label: currentCustomer.name,
            value: currentCustomer.id,
            data: currentCustomer,
          };
          setCustomerFilterValues([currentCustomerOption]);
        }
        setTimeout(() => {
          // Timeout to allow time for new trips or stops query to be made
          setIsChangingCustomers(false);
        }, 200);
      })
      .catch((resp) => {
        setTimeout(() => {
          // Timeout to allow time for new trips or stops query to be made
          setIsChangingCustomers(false);
        }, 200);
        console.error("error", resp);
      });
  };
  const GlobalFilterControl = () => {
    let initialValues: FilterControlValues[] = [];
    if (isAdmin) {
      initialValues.push({
        name: "customer",
        values: customerFilterValues,
      });
    } else {
      const currentCustomer = customers.find(
        (customer) => customer.id === userInfo?.customer_id
      );
      if (currentCustomer) {
        const currentCustomerOption: AutocompleteItem = {
          label: currentCustomer.name,
          value: currentCustomer.id,
          data: currentCustomer,
        };
        initialValues.push({
          name: "customer",
          values: [currentCustomerOption],
        });
      }
    }
    if (dayFilterValue) {
      initialValues.push({
        name: "day",
        values: [dayFilterValue],
      });
    }
    return (
      <GlobalFilterModal
        preloadedCustomers={customers}
        reference={globalFilterControlRef}
        initialValues={initialValues}
        onClose={() => {
          setIsGlobalFiltersModalOpen(false);
          setIsOverlayOpen(false);
        }}
        onSaveChanges={(newValues) => {
          const newDayValue = newValues.find((filter) => filter.name === "day");
          if (newDayValue) {
            const formattedDayValue: AutocompleteItem = {
              label: newDayValue.name,
              value: newDayValue.values[0].value,
            };
            setDayFilterValue(formattedDayValue);
          }
          const formatValues = (filterName: string) => {
            const filter = newValues.find(
              (filter) => filter.name === filterName
            );
            const formattedFilterValues = filter?.values.map((option) => {
              const formattedItem: AutocompleteItem = {
                label: option.label,
                value: option.value,
              };
              return formattedItem;
            });
            return formattedFilterValues ? formattedFilterValues : [];
          };
          const newCustomerValues = newValues.find(
            (filter) => filter.name === "customer"
          );
          if (newCustomerValues) {
            if (isAdmin) {
              setCustomerFilterValues(formatValues("customer"));
            } else {
              if (newCustomerValues.values[0].value) {
                handleChangeCustomer(newCustomerValues?.values[0].value);
              }
            }
          }

          setIsGlobalFiltersModalOpen(false);
          setIsOverlayOpen(false);
        }}
      />
    );
  };
  const TripsFilterControl = () => {
    let initialValues: FilterControlValues[] = [];
    initialValues.push({
      name: "trip_status",
      values: tripStatusFilterValues,
    });
    initialValues.push({
      name: "trip_timeliness",
      values: tripTimelinessFilterValues,
    });
    if (tripsSortMethod) {
      const tripSortName = tripsSortMethod.replace("-", "");
      initialValues.push({
        name: tripSortName,
        values: [{ label: "", value: tripsSortMethod }],
      });
    }
    return (
      <TripsFilterModal
        initialValues={initialValues}
        defaultSortMethod={defaultTripSort}
        onClose={() => {
          setIsGlobalFiltersModalOpen(false);
          setIsOverlayOpen(false);
        }}
        onSaveChanges={(newValues) => {
          const formatValues = (filterName: string) => {
            const filter = newValues.find(
              (filter) => filter.name === filterName
            );
            const formattedFilterValues = filter?.values.map((option) => {
              const formattedItem: AutocompleteItem = {
                label: option.label,
                value: option.value,
              };
              return formattedItem;
            });
            return formattedFilterValues ? formattedFilterValues : [];
          };
          setTripStatusFilterValues(formatValues("trip_status"));
          setTripTimelinessFilterValues(formatValues("trip_timeliness"));

          // Sort by logic
          const allTripsSortByNames = tripsFilters
            .filter((filter) => filter.category === "sortBy")
            .map((filter) => filter.name);
          const activeSortByOption = newValues.find((option) =>
            allTripsSortByNames.includes(option.name)
          );
          if (activeSortByOption && activeSortByOption.values.length > 0) {
            setTripsSortMethod(activeSortByOption.values[0].value);
          }

          setIsTripsFiltersModalOpen(false);
          setIsOverlayOpen(false);
        }}
      />
    );
  };
  const StopsFilterControl = () => {
    let initialValues: FilterControlValues[] = [];
    initialValues.push({
      name: "stop_status",
      values: stopStatusFilterValues,
    });
    initialValues.push({
      name: "stop_timeliness",
      values: stopTimelinessFilterValues,
    });
    if (stopsSortMethod) {
      const stopSortName = stopsSortMethod.replace("-", "");
      initialValues.push({
        name: stopSortName,
        values: [{ label: "", value: stopsSortMethod }],
      });
    }
    return (
      <StopsFilterModal
        initialValues={initialValues}
        defaultSortMethod={defaultStopSort}
        onClose={() => {
          setIsGlobalFiltersModalOpen(false);
          setIsOverlayOpen(false);
        }}
        onSaveChanges={(newValues) => {
          const formatValues = (filterName: string) => {
            const filter = newValues.find(
              (filter) => filter.name === filterName
            );
            const formattedFilterValues = filter?.values.map((value) => {
              const formattedItem: AutocompleteItem = value;
              return formattedItem;
            });
            return formattedFilterValues ? formattedFilterValues : [];
          };
          setStopStatusFilterValues(formatValues("stop_status"));
          setStopTimelinessFilterValues(formatValues("stop_timeliness"));

          // Sort by logic
          const allStopsSortByNames = stopsFilters
            .filter((filter) => filter.category === "sortBy")
            .map((filter) => filter.name);
          const activeSortByOption = newValues.find((option) =>
            allStopsSortByNames.includes(option.name)
          );
          if (activeSortByOption && activeSortByOption.values.length > 0) {
            setStopsSortMethod(activeSortByOption.values[0].value);
          }

          setIsStopsFiltersModalOpen(false);
          setIsOverlayOpen(false);
        }}
      />
    );
  };

  return (
    <main
      className={classNames(
        "page__content live-tracking",
        {
          "live-tracking--trips": tab === LiveTrackingTab.Trips,
          "live-tracking--stops": tab === LiveTrackingTab.Stops,
        },
        classes
      )}
    >
      <div className="live-tracking__top-section bg-primary-50 sm:bg-primary-25 p-8 sm:p-16 pb-0 sm:pb-0">
        <div className="flex justify-between items-start flex-col md:flex-row">
          <div className="flex flex-col">
            <h1 className="text-display-xs sm:text-display-sm font-semibold">
              Live Tracking
            </h1>
            {DateInfo()}
          </div>
          <div ref={searchContainerRef} className="live-tracking__search-wrap">
            <div
              className={classNames(
                "live-tracking__search-container",
                {
                  "live-tracking__search-container--top": isMd,
                  "flex gap-4 w-full bg-primary-50 sm:bg-primary-25": !isMd,
                },
                { "live-tracking__search-container--sticky": isSearchSticky }
              )}
            >
              {isAdvancedSearch ? AutocompleteSearch() : SimpleSearch()}
              <div className="relative">
                <StyledButton
                  onClick={(e) => {
                    if (isMd) {
                      e.stopPropagation();
                    } else {
                      setOverlayContent(GlobalFilterControl());
                      setIsOverlayOpen(true);
                    }
                    setIsGlobalFiltersModalOpen(true);
                  }}
                  size="sm"
                  color="primary"
                  outlined
                  iconOnly
                >
                  <FilterLinesIcon
                    width="22"
                    height="22"
                    fill={allColors.primary[500]}
                  />
                  <span className="ml-2">
                    {customerFilterValues.length > 0 ? "2" : "1"}
                  </span>
                  <span className="sr-only">Change filters</span>
                </StyledButton>
                {isGlobalFiltersModalOpen && isMd && GlobalFilterControl()}
              </div>
            </div>
          </div>
        </div>
        <StyledTabs
          tabType={TabType.Folder}
          labels={["Trips", "Stops"]}
          extraClasses="md:mt-20"
          value={tab === LiveTrackingTab.Stops ? 1 : 0}
          onChange={(_, newValue) => {
            if (newValue === 0) {
              changeTab(LiveTrackingTab.Trips);
            }
            if (newValue === 1) {
              changeTab(LiveTrackingTab.Stops);
            }
          }}
        />
      </div>
      <div
        className={classNames("live-tracking__bottom-section", {
          "pb-20": isLoadingNewResults && !isMd,
        })}
      >
        <div className="live-tracking__content-container">
          <div className="live-tracking__content-container-header">
            {tab === LiveTrackingTab.Trips && (
              <h1 className="text-lg font-semibold">
                Trips{" "}
                {tripsTotalCount !== undefined ? `(${tripsTotalCount})` : null}
              </h1>
            )}
            {tab === LiveTrackingTab.Stops && (
              <h1 className="text-lg font-semibold">
                Stops{" "}
                {stopsTotalCount !== undefined ? `(${stopsTotalCount})` : null}
              </h1>
            )}
            <div className="live-tracking__filters">
              {!isMd ? (
                <div className="flex justify-end py-8 sm:py-0">
                  <button
                    className="text-md font-medium text-gray-600 flex items-center"
                    onClick={() => {
                      if (tab === LiveTrackingTab.Trips) {
                        setOverlayContent(TripsFilterControl());
                        setIsTripsFiltersModalOpen(true);
                      } else {
                        setOverlayContent(StopsFilterControl());
                        setIsStopsFiltersModalOpen(true);
                      }
                      setIsOverlayOpen(true);
                    }}
                  >
                    <SortArrowsIcon
                      stroke={allColors.gray[600]}
                      height="16"
                      width="16"
                      classes="mr-3"
                    />{" "}
                    Filter/Sort{" "}
                    {tab === LiveTrackingTab.Stops &&
                      stopStatusFilterValues.length +
                        stopTimelinessFilterValues.length >
                        0 && (
                        <span className="text-primary-600 ml-4">
                          (
                          {stopStatusFilterValues.length +
                            stopTimelinessFilterValues.length}{" "}
                          selected)
                        </span>
                      )}
                    {tab === LiveTrackingTab.Trips &&
                      tripStatusFilterValues.length +
                        tripTimelinessFilterValues.length >
                        0 && (
                        <span className="text-primary-600 ml-4">
                          (
                          {tripStatusFilterValues.length +
                            tripTimelinessFilterValues.length}{" "}
                          selected)
                        </span>
                      )}
                  </button>
                </div>
              ) : (
                <React.Fragment>
                  {tab === LiveTrackingTab.Trips && (
                    <React.Fragment>
                      <TripStatusFilter
                        label="Status"
                        value={tripStatusFilterValues}
                        onChange={(_, newValues) => {
                          const newSelectedValues =
                            getNewMultiAutocompleteValues(newValues);
                          setTripStatusFilterValues(newSelectedValues);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        hideLabel={tripStatusFilterValues.length > 0}
                        extraClasses="live-tracking__filter live-tracking__filter--trip-status"
                      />
                      {/* TODO: Re-enable timeliness filters once APIs are set up for them */}
                      {/* <TimelinessFilter
                        value={tripTimelinessFilterValues}
                        onChange={(_, newValues) => {
                          const newSelectedValues =
                            getNewMultiAutocompleteValues(newValues);
                          setTripTimelinessFilterValues(newSelectedValues);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        hideLabel={tripTimelinessFilterValues.length > 0}
                        extraClasses="live-tracking__filter live-tracking__filter--trip-timeliness"
                      /> */}
                    </React.Fragment>
                  )}
                  {tab === LiveTrackingTab.Stops && (
                    <React.Fragment>
                      <StopStatusFilter
                        label="Status"
                        value={stopStatusFilterValues}
                        onChange={(_, newValues) => {
                          const newSelectedValues =
                            getNewMultiAutocompleteValues(newValues);
                          setStopStatusFilterValues(newSelectedValues);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        hideLabel={stopStatusFilterValues.length > 0}
                        extraClasses="live-tracking__filter live-tracking__filter--stop-status"
                      />
                      {/* TODO: Re-enable timeliness filters once APIs are set up for them */}
                      {/* <TimelinessFilter
                        value={stopTimelinessFilterValues}
                        onChange={(_, newValues) => {
                          const newSelectedValues =
                            getNewMultiAutocompleteValues(newValues);
                          setStopTimelinessFilterValues(newSelectedValues);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        hideLabel={stopTimelinessFilterValues.length > 0}
                        extraClasses="live-tracking__filter live-tracking__filter--stop-timeliness"
                      /> */}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {isSm && (
                <StyledToggleButtonGroup
                  groupLabel="Content View"
                  exclusive
                  value={currentViewType}
                  groupClasses="self-start"
                  onChange={(_, newValue) => {
                    if (newValue !== null) {
                      setCurrentViewType(newValue);
                    }
                  }}
                  buttons={[
                    {
                      value: "cards",
                      label: "Card view",
                      content: (
                        <GridIcon
                          width="20"
                          height="20"
                          stroke={allColors.gray[700]}
                        />
                      ),
                    },
                    {
                      value: "table",
                      label: "Table view",
                      disabled: !isMd,
                      content: (
                        <TableIcon
                          width="20"
                          height="20"
                          stroke={allColors.gray[700]}
                        />
                      ),
                    },
                  ]}
                />
              )}
            </div>
          </div>
          {(isChangingCustomers || isLoadingNewResults) &&
            currentViewType === "cards" && (
              <LoadingSpinner centered classes="my-96" />
            )}
          {noResults && !isLoadingNewResults && currentViewType === "cards" && (
            <NoResults classes="live-tracking__no-results" />
          )}
          {tab === LiveTrackingTab.Trips &&
            !noResults &&
            !isLoadingNewResults &&
            currentViewType === "cards" && (
              <div className="live-tracking__inner-content-container live-tracking__inner-content-container--cards">
                {trips.map((trip, index) => {
                  return (
                    <TripCard
                      key={index}
                      trip={trip}
                      onClick={() => {
                        openTripDrawer(trip);
                      }}
                    />
                  );
                })}
              </div>
            )}
          {tab === LiveTrackingTab.Trips && currentViewType === "table" && (
            <div className="live-tracking__inner-content-container live-tracking__inner-content-container--table">
              <TripsTable
                trips={trips}
                sortValue={tripsSortMethod}
                onChangeSortValue={(sortMethod) =>
                  setTripsSortMethod(sortMethod)
                }
                onClickItem={(trip) => {
                  openTripDrawer(trip);
                }}
                isLoadingItems={isChangingCustomers || isLoadingNewResults}
                noResults={<NoResults classes="live-tracking__no-results" />}
              />
            </div>
          )}
          {tab === LiveTrackingTab.Stops &&
            !noResults &&
            !isLoadingNewResults &&
            currentViewType === "cards" && (
              <div className="live-tracking__inner-content-container live-tracking__inner-content-container--cards">
                {stops.map((stop, index) => {
                  return <StopCard key={index} stop={stop} />;
                })}
              </div>
            )}
          {tab === LiveTrackingTab.Stops && currentViewType === "table" && (
            <div className="live-tracking__inner-content-container live-tracking__inner-content-container--table">
              <StopsTable
                stops={stops}
                sortValue={stopsSortMethod}
                onChangeSortValue={(sortMethod) =>
                  setStopsSortMethod(sortMethod)
                }
                isLoadingItems={isChangingCustomers || isLoadingNewResults}
                noResults={<NoResults classes="live-tracking__no-results" />}
              />
            </div>
          )}
          {isSm && paginationCount > 1 && (
            <div className="pagination__container flex align-center justify-center pt-6 pb-8">
              <StyledPagination
                count={paginationCount}
                page={paginationPage}
                onChangePage={(_, page) => setPaginationPage(page)}
              />
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
