import classNames from "classnames";
import { allColors } from "../../util/colors";
import { useOverlayContext } from "../../context/OverlayContext";
import CloseLgIcon from "../common/svg/CloseLg";

export type ModalProps = {
  children?: React.ReactNode;
  onClose?: () => void;
  classes?: string;
};

export default function Modal(props: ModalProps) {
  const { children, onClose, classes } = props;

  const { isOverlayOpen } = useOverlayContext();

  return (
    <div
      className={classNames(
        "modal",
        { "modal--hidden": !isOverlayOpen },
        classes
      )}
    >
      {onClose && (
        <button className="support-modal__close-button" onClick={onClose}>
          <CloseLgIcon stroke={allColors.gray[400]} />
          <span className="sr-only">Close banner</span>
        </button>
      )}
      <div className="modal__inner-section">{children}</div>
    </div>
  );
}
