import { AxiosResponse } from "axios";
import omniAxios from "./axios";
import { Customer } from "../types/customer.type";

export enum CustomerSortMethods {
  Name = "name",
  ReverseName = "-name",
  FullName = "full_name",
  ReverseFullName = "-full_name",
}
export interface FetchCustomersRequestBody {
  text?: string;
  page?: number;
  ids?: string[];
  ordering?: CustomerSortMethods;
}
export interface FetchCustomersResponseBody {
  count?: number | null;
  next: null;
  previous: null;
  results: Customer[];
}
export const fetchCustomers = async (
  text?: string,
  page?: number,
  ids?: string[],
  ordering?: CustomerSortMethods
) => {
  return await omniAxios
    .get<
      FetchCustomersResponseBody,
      AxiosResponse<FetchCustomersResponseBody, FetchCustomersRequestBody>,
      FetchCustomersRequestBody
    >("/v1/customers/", { params: { text, page, ids, ordering } })
    .then((resp) => resp.data);
};

export interface AllCustomerSettingsRequestBody {
  customer_id?: string;
  name?: CustomerSettingName | string;
}

export interface AllCustomerSettingsResponseBody {
  count?: number | null;
  next: null;
  previous: null;
  results: CustomerSetting[];
}
export interface CustomerSetting {
  name: CustomerSettingName | string;
  value?: string | number;
  level?: CustomerSettingLevel;
}
export enum CustomerSettingName {
  ManualBackup = "MANUAL_BACKUP",
  ManualBackupUrl = "MANUAL_BACKUP_URL",
  EstTimeStopsDisplay = "EST_TIME_STOPS_DISPLAY",
  EstTimeStopsCalcGoogle = "EST_TIME_STOPS_CALC_GOOGLE",
}
export enum CustomerSettingLevel {
  Customer = "customer",
  System = "system",
}

export const getAllCustomerSettings = async (
  payload: AllCustomerSettingsRequestBody
) => {
  return await omniAxios
    .get<
      AllCustomerSettingsResponseBody,
      AxiosResponse<
        AllCustomerSettingsResponseBody,
        AllCustomerSettingsRequestBody
      >,
      AllCustomerSettingsRequestBody
    >(`/custom-settings/`, { params: { ...payload } })
    .then((resp) => resp.data)
    .catch((error) => console.log(error));
};

export interface SingleCustomerSettingRequestBody {
  customer_id?: string;
  name?: CustomerSettingName;
}
export interface SingleCustomerSettingResponseBody extends CustomerSetting {}

export const getSingleCustomerSetting = async (
  payload: SingleCustomerSettingRequestBody
) => {
  return await omniAxios
    .get<
      SingleCustomerSettingResponseBody,
      AxiosResponse<
        SingleCustomerSettingResponseBody,
        SingleCustomerSettingRequestBody
      >,
      SingleCustomerSettingRequestBody
    >(`/custom-settings/`, { params: { ...payload } })
    .then((resp) => resp.data);
};
