import classNames from "classnames";
import FilterControl from "../../components/filter-control/FilterControl";
import { dayOptions } from "../../components/form/autocomplete/day-filter/DayFilter";
import { useMdBreakpoint } from "../../util/breakpoints";
import { FilterControlProps } from "../../components/filter-control/types";
import { useUserContext } from "../../context/UserContext";
import { checkIsStaff, useAsyncEffect } from "../../util/util";
import { useMemo, useState } from "react";
import { Customer } from "../../types/customer.type";
import { CustomerSortMethods, fetchCustomers } from "../../api/customer";
import { AutocompleteItem } from "../../components/form/autocomplete/types";

export interface GlobalFilterModalProps extends FilterControlProps {
  preloadedCustomers?: Customer[];
}

export default function GlobalFilterModal(props: GlobalFilterModalProps) {
  const { preloadedCustomers, ...otherProps } = props;

  const isMd = useMdBreakpoint();
  const { userInfo } = useUserContext();

  const [customers, setCustomers] = useState<Customer[]>([]);

  const isAdmin = checkIsStaff(userInfo?.admin_user);

  const { loading } = useAsyncEffect(async () => {
    if (!preloadedCustomers || preloadedCustomers.length === 0) {
      const customersResp = await fetchCustomers(
        undefined,
        undefined,
        undefined,
        CustomerSortMethods.Name
      );
      setCustomers(customersResp.results);
    }
  }, []);

  const convertCustomersToOptions = (customers: Customer[]) => {
    return customers.map((customer: Customer) => ({
      label: customer.name,
      value: customer.id,
      data: customer,
    }));
  };
  const customerOptions: AutocompleteItem[] = useMemo(() => {
    return convertCustomersToOptions(customers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  const dayFilter = {
    label: "Date",
    name: "day",
    singleSelect: true,
    options: dayOptions,
  };

  const customerFilter = {
    label: isAdmin ? "Customer" : "Deployment",
    name: "customer",
    singleSelect: !isAdmin,
    options: preloadedCustomers
      ? convertCustomersToOptions(preloadedCustomers)
      : customerOptions,
  };
  const globalFilters =
    (preloadedCustomers && preloadedCustomers.length > 1) ||
    customerOptions.length > 1
      ? [customerFilter, dayFilter]
      : [dayFilter];

  return (
    <FilterControl
      {...otherProps}
      isLoading={preloadedCustomers ? false : loading}
      label="Global Filters"
      hideClearButton
      filters={globalFilters}
      classes={classNames("live-tracking__filter-control", {
        "live-tracking__filter-control--desktop": isMd,
        "live-tracking__filter-control--mobile": !isMd,
      })}
    />
  );
}
