import { StopInfo } from "../api/stop";
import { AV } from "./av";

export interface TripReponse {
  count: number;
  results: Trip[];
}

export interface Trip {
  id: string;
  customer_id: string;
  scheduled_start: string;
  scheduled_end: string;
  template?: Template;
  status: TripStatus;
  itinerary: Itinerary;

  // New for Griffin
  timezone: string;
  scheduled_trip_distance?: number;

  // TODO: Only send full AV info when needed.
  av: AV;
  // For querying a list of trips, we only need the av_id and long_av_id
  // av_id: string;
  // long_av_id: string;
}

export interface Itinerary {
  id?: string;
  name: string;
  stops: Stop[];
}

export interface Template {
  id?: string;
  external_id?: string;
}

export enum TripStatus {
  Scheduled = "SCHEDULED",
  InProgress = "IN_PROGRESS",
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  Interrupted = "INTERRUPTED",
  TrackingTimeout = "TRACKING_TIMEOUT",
}

export interface Stop {
  id: string;
  customer_id?: string;

  arrived_at: string | null;
  departed_at: string | null;
  scheduled_arrival: string | null;
  scheduled_departure: string | null;
  arrival_status?: TimelinessStatus;
  departure_status?: TimelinessStatus;

  eta?: string | null;
  ett?: number;
  order?: number;
  logged_order?: number | null;
  travel_time?: number;
  service_time?: number;

  site: Site;
  // `stop_info` is an old type, FE will manually convert it to `site` until this is fixed.
  stop_info?: StopInfo;

  event_status: StopEventStatus | null;
  sequence_status: StopSequenceStatus;

  // New for Griffin
  // Need these in v2/stops and trips itinerary
  timezone?: string;
  stops_away?: number;
  total_distance: number | null;

  travelled_distance: number | null;
  elapsed_service_time?: number;
  elapsed_travel_time?: number;

  // Need these in v2/stops only
  trip_id: string;
  trip_status: TripStatus;
  trip_name?: string;
  trip_scheduled_start?: string;
  av_id?: string;
  long_av_id?: string;
  lane_id?: string;
  is_origin?: boolean;
  is_final_destination?: boolean;
}
export interface Site {
  id: string;
  type?: string;
  geometry?: {
    type: string;
    coordinates: [Point[]];
  };
  properties: {
    customer_id?: string;
    name: string;
    dock?: string;
    description?: string;
    location?: string;
    geofence_radius_meters?: number;
  };
}
export type Point = [number, number];
export enum StopEventStatus {
  AwaitingAV = "AWAITING_AV",
  AVArrived = "AV_ARRIVED",
  AVDeparted = "AV_DEPARTED",
}
export enum TimelinessStatus {
  OnTime = "ON_TIME",
  Early = "EARLY",
  Late = "DELAYED",
}
export enum StopSequenceStatus {
  InSequence = "in_sequence",
  OutOfSequence = "out_of_sequence",
  Skipped = "skipped",
  Unplanned = "unplanned",
}
