import classNames from "classnames";
import StyledButton from "../../../components/common/button/StyledButton";
import SignedOutTemplate from "../../../components/templates/SignedOutTemplate";
import SignedOutContainer from "../SignedOutContainer";
import { Formik } from "formik";
import StyledInput from "../../../components/form/input/StyledInput";
import ArrowIcon from "../../../components/common/svg/Arrow";
import { allColors } from "../../../util/colors";
import { SvgDirection, SvgType } from "../../../components/common/svg/types";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import React from "react";
import { useSmBreakpoint } from "../../../util/breakpoints";
import SignUpMailIcon from "./SignUpMailIcon";
import EyeHideIcon from "../../../components/common/svg/EyeHide";
import EyeShowIcon from "../../../components/common/svg/EyeShow";
import SingleAutocomplete from "../../../components/form/autocomplete/SingleAutocomplete";
import { AutocompleteItem } from "../../../components/form/autocomplete/types";
import CheckmarkCircleIcon from "../../../components/common/svg/CheckmarkCircle";
import { ConditionalWrapper } from "../../../util/util";
import StyledTooltip from "../../../components/common/tooltip/StyledTooltip";
import SignUpErrorIcon from "./SignUpErrorIcon";
import {
  FetchUserJobsResponseBody,
  SignUpRequestBody,
  fetchUserJobs,
  signUp,
} from "../../../api/auth";
import { JobCategoryType } from "../../../types/common";
import Snackbar, {
  SnackbarType,
} from "../../../components/common/snackbar/Snackbar";

export type SignUpPageProps = {
  classes?: string;
};

export enum SignUpStep {
  EnterEmail = "0",
  Name = "1",
  Password = "2",
  Role = "3",
  EmailSent = "email-sent",
  AccountCreated = "success",
  ErrorOccurred = "error",
}

// *****************************
//  Note: The "Enter email" step is a separate formik form because
//  initial implementation had an email sent between the "Enter email"
//  and "Name" steps to verify the user's email. If we keep the flow
//  as is it today, we should move email step into the main create account
//  formik container.
// *****************************

export default function SignUpPage(props: SignUpPageProps) {
  const { classes }: SignUpPageProps = props;

  const [stepParams, setStepParams] = useSearchParams();
  const [filterParams, setFilterParams] = useSearchParams();

  const isSm = useSmBreakpoint();
  const navigate = useNavigate();

  const [step, setStep] = useState(stepParams.get("step"));
  const [emailAddress, setEmailAddress] = useState<string | undefined>(
    undefined
  );
  const [resendCountdown, setResendCountdown] = useState(0);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [rawJobInfo, setRawJobInfo] =
    useState<FetchUserJobsResponseBody | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [clearedFieldsIndex, setClearedFieldsIndex] = useState<number>(0);
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const [formSuccessMessage, setFormSuccessMessage] = useState<string | null>(
    null
  );
  const [previousAccountInfoSent, setPreviousAccountInfoSent] =
    useState<SignUpRequestBody | null>(null);

  const isViewWithIconAndBorder =
    isSm &&
    (step === SignUpStep.EmailSent || step === SignUpStep.AccountCreated);

  // Job roles step is currently disabled.
  const skipJobRolesStep = true;

  useEffect(() => {
    fetchUserJobs()
      .then((resp) => {
        // Save response to sort later
        setRawJobInfo(resp);
      })
      .catch((error) => console.error(error));
  }, []);

  const jobCategoryOptions: AutocompleteItem[] = useMemo(() => {
    if (!emailAddress) {
      return [];
    }
    const isInternalUser = emailAddress.includes("gatik.ai");
    const filteredJobCategories = rawJobInfo?.user_job_categories.filter(
      (category) =>
        isInternalUser
          ? category.type === JobCategoryType.Internal
          : category.type === JobCategoryType.External
    );
    const sortedJobCategories =
      filteredJobCategories &&
      filteredJobCategories.sort((a, b) => (a.order > b.order ? 1 : -1));
    if (sortedJobCategories) {
      return sortedJobCategories.map((category) => ({
        label: category.name,
        value: category.id,
        data: category.id,
      }));
    } else {
      return [];
    }
  }, [emailAddress, rawJobInfo]);
  const jobFunctionOptions: AutocompleteItem[] = useMemo(() => {
    const filteredJobs = rawJobInfo?.user_jobs.filter(
      (job) => job.category === selectedCategory
    );
    const sortedJobs =
      filteredJobs && filteredJobs.sort((a, b) => (a.order > b.order ? 1 : -1));
    if (sortedJobs) {
      return sortedJobs.map((job) => ({
        label: job.name,
        value: job.id,
        data: job.id,
      }));
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const jobSeniorityOptions: AutocompleteItem[] = useMemo(() => {
    const sortedSeniorityLevels = rawJobInfo?.user_job_seniorities.sort(
      (a, b) => (a.order > b.order ? 1 : -1)
    );
    if (sortedSeniorityLevels) {
      return sortedSeniorityLevels.map((seniorityLevel) => ({
        label: seniorityLevel.name,
        value: seniorityLevel.id,
        data: seniorityLevel.id,
      }));
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    resendCountdown > 0 &&
      setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
  }, [resendCountdown]);

  useEffect(() => {
    // If there's no email value and the user is in the sign up flow, reset to the first step.
    if (
      !emailAddress &&
      (step === SignUpStep.Name ||
        step === SignUpStep.Password ||
        step === SignUpStep.Role)
    ) {
      setStep(SignUpStep.EnterEmail);
      stepParams.delete("step");
      setStepParams(stepParams);
    } else {
      setStep(stepParams.get("step"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepParams]);

  const EmailSchema = Yup.object().shape({
    // have to use this long regex because Yup's email validation is not great
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email address"
      )
      .required("Required"),
  });
  const NameSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
  });
  const PasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(9, "Must be at least 9 characters.")
      .matches(/(?=.*\W)/, "Must contain at least 1 symbol.")
      .matches(/(?=.*[A-Z])/, "Must contain at least 1 uppercase.")
      .matches(/(?=.*[a-z])/, "Must contain at least 1 lowercase.")
      .matches(/(?=.*\d)/, "Must contain at least 1 number.")
      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords don't match")
      .required("Required"),
  });
  const RoleSchema = Yup.object().shape({
    jobCategory: Yup.string().required("Required"),
    jobFunction: Yup.string().required("Required"),
    seniority: Yup.string().required("Required"),
  });

  const sendEmail = async (accountInfo: SignUpRequestBody) => {
    if (accountInfo.email) {
      setPreviousAccountInfoSent(accountInfo);
      setResendCountdown(30);
      await signUp(accountInfo)
        .then((response) => {
          if (response?.detail) {
            setFormSuccessMessage(response.detail);
          }
          setStepParams({
            step: SignUpStep.EmailSent,
          });
          setStep(SignUpStep.EmailSent);
        })
        .catch((error) => {
          if (error?.response?.data?.password) {
            setFormErrorMessage(error?.response?.data?.password);
          } else if (error?.response?.data?.email) {
            setFormErrorMessage(error?.response?.data?.email);
          } else {
            setFormErrorMessage(
              "Something went wrong. Try sending again or restarting the sign up flow."
            );
          }
        });
    } else {
      setFormErrorMessage(
        "Something went wrong. Please ensure an email address is given."
      );
    }
  };
  const EnterEmailForm = () => {
    return (
      <React.Fragment>
        <div className="text-center mb-12">
          <h1 className="text-display-xs sm:text-display-sm font-semibold mb-2">
            Sign up
          </h1>
          <p className="text-md text-gray-600 font-medium">
            Please use your work email to sign up.
          </p>
        </div>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={EmailSchema}
          onSubmit={(values) => {
            setEmailAddress(values.email);
            setStepParams({
              step: SignUpStep.Name,
            });
            setStep(SignUpStep.Name);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            let isErrored = isSubmitting || typeof errors.email === "string";
            return (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col items-stretch gap-10"
              >
                <StyledInput
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Enter your email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email ? errors.email : undefined}
                />
                <StyledButton
                  color="primary"
                  isSubmit
                  disabled={isErrored || values.email === ""}
                >
                  Next{" "}
                  <ArrowIcon
                    width="20"
                    height="20"
                    stroke={
                      isErrored || values.email === ""
                        ? allColors.gray[400]
                        : allColors.white
                    }
                    direction={SvgDirection.Right}
                    classes="ml-4"
                  />
                </StyledButton>
              </form>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  };

  const CreateAccountFlow = () => {
    return (
      <React.Fragment>
        <div className="text-center sm:text-left mb-12">
          <h1 className="text-display-xs sm:text-display-sm font-semibold mb-2">
            Sign up
          </h1>
          <p className="text-md text-gray-600 font-medium">Step {step} of 3</p>
        </div>
        <Formik
          initialValues={{
            email: emailAddress,
            firstName: "",
            lastName: "",
            password: "",
            confirmPassword: "",
            jobCategory: "",
            jobFunction: "",
            seniority: "",
          }}
          validationSchema={
            step === SignUpStep.Name
              ? NameSchema
              : step === SignUpStep.Password
                ? PasswordSchema
                : RoleSchema
          }
          onSubmit={(values) => {
            if (step === SignUpStep.Name) {
              setStepParams({
                step: SignUpStep.Password,
              });
              setStep(SignUpStep.Password);
            }
            if (step === SignUpStep.Password) {
              if (skipJobRolesStep) {
                sendEmail({
                  email: emailAddress,
                  first_name: values.firstName,
                  last_name: values.lastName,
                  password: values.password,
                  confirm_password: values.confirmPassword,
                });
              } else {
                setStepParams({
                  step: SignUpStep.Role,
                });
                setStep(SignUpStep.Role);
              }
            }
            if (step === SignUpStep.Role) {
              sendEmail({
                email: emailAddress,
                first_name: values.firstName,
                last_name: values.lastName,
                password: values.password,
                confirm_password: values.confirmPassword,
                profile: {
                  job_category: values.jobCategory,
                  job_name: values.jobFunction,
                  job_seniority: values.seniority,
                },
              });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            const isStepOneErrored =
              typeof errors.email === "string" ||
              typeof errors.firstName === "string" ||
              typeof errors.lastName === "string" ||
              values.email === "" ||
              values.firstName === "" ||
              values.lastName === "";

            const isStepTwoErrored =
              typeof errors.password === "string" ||
              typeof errors.confirmPassword === "string" ||
              values.password === "" ||
              values.confirmPassword === "";

            const isStepThreeErrored =
              typeof errors.jobCategory === "string" ||
              typeof errors.jobFunction === "string" ||
              typeof errors.seniority === "string" ||
              values.jobCategory === "" ||
              values.jobFunction === "" ||
              values.seniority === "";

            const PasswordCriteriaText = (
              text: string,
              criteria: boolean | undefined | null,
              testId?: string
            ) => {
              return (
                <span
                  className={classNames("sign-up-page__password-rule", {
                    "sign-up-page__password-rule--success": criteria,
                  })}
                  data-testid={testId}
                >
                  <CheckmarkCircleIcon
                    type={SvgType.Solid}
                    width="16"
                    height="16"
                    fill={
                      criteria ? allColors.success[600] : allColors.gray[500]
                    }
                    classes="mr-2 mb-px"
                  />
                  {text}
                </span>
              );
            };
            return (
              <React.Fragment>
                {step === SignUpStep.Name && (
                  <form
                    onSubmit={handleSubmit}
                    className="flex flex-col items-stretch gap-10"
                  >
                    <div className="flex flex-col sm:flex-row justify-between gap-8">
                      <StyledInput
                        type="text"
                        name="firstName"
                        label="First name"
                        placeholder="Enter first name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        error={touched.firstName ? errors.firstName : undefined}
                      />
                      <StyledInput
                        type="text"
                        name="lastName"
                        label="Last name"
                        placeholder="Enter last name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        error={touched.lastName ? errors.lastName : undefined}
                      />
                    </div>
                    <StyledInput
                      disabled
                      type="email"
                      name="email"
                      label="Email"
                      placeholder="Enter your email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={touched.email ? errors.email : undefined}
                    />
                    <StyledButton
                      color="primary"
                      isSubmit
                      disabled={isStepOneErrored}
                      data-testid="name-next-button"
                    >
                      Next{" "}
                      <ArrowIcon
                        width="20"
                        height="20"
                        stroke={
                          isStepOneErrored
                            ? allColors.gray[400]
                            : allColors.white
                        }
                        direction={SvgDirection.Right}
                        classes="ml-4"
                      />
                    </StyledButton>
                  </form>
                )}
                {step === SignUpStep.Password && (
                  <form
                    onSubmit={handleSubmit}
                    className="flex flex-col items-stretch gap-10"
                  >
                    <StyledInput
                      type={showPassword ? "text" : "password"}
                      name="password"
                      label="Create password"
                      placeholder="Create password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      error={
                        touched.password && errors.password === "Required"
                          ? errors.password
                          : undefined
                      }
                      endIcon={
                        <button
                          type="button"
                          className="ml-4"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <span className="sr-only">
                            {showPassword ? "Hide password" : "Show password"}
                          </span>
                          {!showPassword ? (
                            <EyeHideIcon
                              type={SvgType.Solid}
                              fill={allColors.grayLightMode[500]}
                            />
                          ) : (
                            <EyeShowIcon
                              type={SvgType.Solid}
                              fill={allColors.grayLightMode[500]}
                            />
                          )}
                        </button>
                      }
                    />
                    <div className="flex flex-wrap gap-2">
                      {PasswordCriteriaText(
                        "9 characters",
                        values.password.length >= 9,
                        "error-characters"
                      )}
                      {PasswordCriteriaText(
                        "1 symbol",
                        /(?=.*\W)/.test(values.password),
                        "error-symbol"
                      )}
                      {PasswordCriteriaText(
                        "1 uppercase",
                        /(?=.*[A-Z])/.test(values.password),
                        "error-uppercase"
                      )}
                      {PasswordCriteriaText(
                        "1 lowercase",
                        /(?=.*[a-z])/.test(values.password),
                        "error-lowercase"
                      )}
                      {PasswordCriteriaText(
                        "1 number",
                        /(?=.*\d)/.test(values.password),
                        "error-number"
                      )}
                    </div>
                    <StyledInput
                      type={showPassword ? "text" : "password"}
                      name="confirmPassword"
                      label="Confirm password"
                      placeholder="Confirm new password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      error={
                        touched.confirmPassword
                          ? errors.confirmPassword
                          : undefined
                      }
                      endIcon={
                        <button
                          type="button"
                          className="ml-4"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <span className="sr-only">
                            {showPassword ? "Hide password" : "Show password"}
                          </span>
                          {!showPassword ? (
                            <EyeHideIcon
                              type={SvgType.Solid}
                              fill={allColors.grayLightMode[500]}
                            />
                          ) : (
                            <EyeShowIcon
                              type={SvgType.Solid}
                              fill={allColors.grayLightMode[500]}
                            />
                          )}
                        </button>
                      }
                    />
                    <ConditionalWrapper
                      condition={
                        typeof errors.password === "string" ||
                        typeof errors.confirmPassword === "string"
                      }
                      wrapper={(children: any) => {
                        return (
                          <StyledTooltip
                            placement="top"
                            header={errors.password || errors.confirmPassword}
                          >
                            {children}
                          </StyledTooltip>
                        );
                      }}
                    >
                      <StyledButton
                        color="primary"
                        isSubmit
                        disabled={isStepTwoErrored}
                        data-testid="password-next-button"
                        classes="w-full"
                      >
                        Next{" "}
                        <ArrowIcon
                          width="20"
                          height="20"
                          stroke={
                            isStepTwoErrored
                              ? allColors.gray[400]
                              : allColors.white
                          }
                          direction={SvgDirection.Right}
                          classes="ml-4"
                        />
                      </StyledButton>
                    </ConditionalWrapper>
                  </form>
                )}
                {step === SignUpStep.Role && (
                  <form
                    onSubmit={handleSubmit}
                    className="flex flex-col items-stretch gap-10"
                  >
                    <label>
                      <span className="block text-sm text-gray-600 font-medium mb-2">
                        What is your job category?
                      </span>
                      <SingleAutocomplete
                        label="Please make a selection"
                        options={jobCategoryOptions}
                        onBlur={handleBlur}
                        onChange={(_, child) => {
                          setSelectedCategory(
                            child?.value ? child?.value : null
                          );
                          setFieldValue("jobCategory", child?.value);
                          setClearedFieldsIndex(clearedFieldsIndex + 1);
                          setFieldValue("jobFunction", null);
                          setFieldValue("seniority", null);
                        }}
                      />
                      <p className="input__error">{errors.jobCategory}</p>
                    </label>
                    <label>
                      <span className="block text-sm text-gray-600 font-medium mb-2">
                        What is your job function?
                      </span>
                      <SingleAutocomplete
                        key={`jobFunction${clearedFieldsIndex}`}
                        label="Please make a selection"
                        options={jobFunctionOptions}
                        onBlur={handleBlur}
                        onChange={(_, child) => {
                          setFieldValue("jobFunction", child?.value);
                        }}
                        isDisabled={!selectedCategory}
                      />
                      <p className="input__error">{errors.jobFunction}</p>
                    </label>
                    <label>
                      <span className="block text-sm text-gray-600 font-medium mb-2">
                        What is your level of seniority?
                      </span>
                      <SingleAutocomplete
                        key={`jobSeniority${clearedFieldsIndex}`}
                        label="Please make a selection"
                        options={jobSeniorityOptions}
                        onChange={(_, child) => {
                          setFieldValue("seniority", child?.value);
                        }}
                        onBlur={handleBlur}
                        isDisabled={!selectedCategory}
                      />
                      <p className="input__error">{errors.seniority}</p>
                    </label>
                    <StyledButton
                      color="primary"
                      isSubmit
                      disabled={isStepThreeErrored}
                      data-testid="create-account-button"
                    >
                      Sign up{" "}
                      <ArrowIcon
                        width="20"
                        height="20"
                        stroke={
                          isStepThreeErrored
                            ? allColors.gray[400]
                            : allColors.white
                        }
                        direction={SvgDirection.Right}
                        classes="ml-4"
                      />
                    </StyledButton>
                    <p className="text-xs text-gray-500">
                      By clicking "Sign up" or signing in with SSO, you agree to
                      the Gatik TOS and Privacy. You may receive product,
                      service, and events email updates from Figma and can
                      opt-out at any time.
                    </p>
                  </form>
                )}
              </React.Fragment>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  };
  const EmailSent = () => {
    return (
      <React.Fragment>
        <div className="sign-up-page__image-container">
          <SignUpMailIcon />
        </div>
        <div className="text-center mb-12 mt-16">
          <h1 className="text-display-xs sm:text-display-sm font-semibold mb-2">
            Check your inbox
          </h1>
          <p className="text-md text-gray-600 font-medium">
            We've sent you an email. Please follow the instructions to access
            your account.
          </p>
        </div>
        <StyledButton
          size={isSm ? "md" : "2xl"}
          color="primary"
          outlined
          classes="mb-6 w-full"
          disabled={resendCountdown !== 0}
          onClick={() => {
            if (previousAccountInfoSent) {
              sendEmail(previousAccountInfoSent);
            } else {
              setFormErrorMessage(
                "Something went wrong. Try sending again or restarting the sign up flow."
              );
            }
          }}
        >
          Resend email {resendCountdown !== 0 && `(${resendCountdown}s)`}
        </StyledButton>
        <div className="text-center mt-2">
          <StyledButton
            size="md"
            color="secondary"
            textOnly
            onClick={() => navigate("/login")}
          >
            Go back to login page
          </StyledButton>
        </div>
      </React.Fragment>
    );
  };
  const SuccessScreen = () => {
    return (
      <React.Fragment>
        <div className="sign-up-page__image-container">
          <SignUpMailIcon />
        </div>
        <div className="text-center mb-12 mt-16">
          <h1 className="text-display-xs sm:text-display-sm font-semibold mb-2">
            Success!
          </h1>
          <p className="text-md text-gray-600 font-medium">
            Your account has been successfully created! Welcome to Omni.
          </p>
        </div>
        <StyledButton
          size={isSm ? "md" : "2xl"}
          color="primary"
          classes="mb-6 w-full"
          onClick={() => navigate("/login")}
        >
          Start using Omni
        </StyledButton>
      </React.Fragment>
    );
  };
  const ErrorScreen = () => {
    return (
      <React.Fragment>
        <div className="sign-up-page__image-container">
          <SignUpErrorIcon />
        </div>
        <div className="text-center mb-12 mt-16">
          <h1 className="text-display-xs sm:text-display-sm font-semibold mb-2">
            An error has occurred.
          </h1>
          <p className="text-md text-gray-600 font-medium">
            Something went wrong, please contact Gatik to finish setting up your
            account.
          </p>
        </div>
        <StyledButton
          size={isSm ? "md" : "2xl"}
          color="primary"
          outlined
          classes="mb-6 w-full"
          onClick={() => {
            filterParams.set("modal", "support");
            setFilterParams(filterParams);
          }}
        >
          Contact Gatik
        </StyledButton>
      </React.Fragment>
    );
  };

  return (
    <SignedOutTemplate>
      <SignedOutContainer
        innerContentBorderVariant={isViewWithIconAndBorder}
        headerButtonType="sign_in"
      >
        <div
          className={classNames(
            "sign-up-page",
            {
              "sign-up-page--with-inner-border": isViewWithIconAndBorder,
            },
            classes
          )}
        >
          {(!step || step === SignUpStep.EnterEmail) && EnterEmailForm()}
          {step === SignUpStep.EmailSent && EmailSent()}
          {(step === SignUpStep.Name ||
            step === SignUpStep.Password ||
            step === SignUpStep.Role) &&
            CreateAccountFlow()}
          {step === SignUpStep.AccountCreated && SuccessScreen()}
          {step === SignUpStep.ErrorOccurred && ErrorScreen()}
        </div>
        {formErrorMessage && (
          <Snackbar
            type={SnackbarType.Error}
            body={formErrorMessage}
            onDismiss={() => setFormErrorMessage(null)}
            containerClasses="sign-up-page__snackbar-container"
          />
        )}
        {formSuccessMessage && (
          <Snackbar
            type={SnackbarType.Success}
            body={formSuccessMessage}
            onDismiss={() => setFormSuccessMessage(null)}
            containerClasses="sign-up-page__snackbar-container"
          />
        )}
      </SignedOutContainer>
    </SignedOutTemplate>
  );
}
