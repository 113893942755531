import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/common/loading-spinner/LoadingSpinner";
import SignedOutTemplate from "../../../components/templates/SignedOutTemplate";
import { activate } from "../../../api/auth";
import { useAsyncEffect } from "../../../util/util";
import { SignUpStep } from "./SignUpPage";

export type SignUpActivationRedirectProps = {};

export default function SignUpActivationRedirect(
  props: SignUpActivationRedirectProps
) {
  const { token } = useParams();
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    if (token) {
      await activate(token)
        .then(() => {
          navigate({
            pathname: `/sign-up`,
            search: `?step=${SignUpStep.AccountCreated}`,
          });
        })
        .catch((error) => {
          console.error(error);
          navigate({
            pathname: `/sign-up`,
            search: `?step=${SignUpStep.ErrorOccurred}`,
          });
        });
    }
  }, [token]);

  return (
    <SignedOutTemplate>
      <LoadingSpinner />
    </SignedOutTemplate>
  );
}
