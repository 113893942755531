import classNames from "classnames";
import { useEffect, useState } from "react";
import { useOverlayContext } from "../../context/OverlayContext";
import Overlay from "../common/overlay/Overlay";
import { useLocalStorage } from "../../util/local-storage";
import { UserInfo } from "../../types/common";
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkIsStaff } from "../../util/util";
import SupportModal from "../modals/SupportModal";
import { OVERLAY_DELAY } from "../../util/constants";
import { fetchUserInfo } from "../../api/auth";

export type SignedOutTemplateProps = {
  isLanding?: boolean;
  blockRedirect?: boolean;
  children: any;
  classes?: string;
};

export default function SignedOutTemplate(props: SignedOutTemplateProps) {
  const {
    isLanding,
    blockRedirect,
    children,
    classes,
  }: SignedOutTemplateProps = props;

  const { isOverlayOpen, overlayContent, setOverlayContent, setIsOverlayOpen } =
    useOverlayContext();
  const navigate = useNavigate();
  const [localStorageValue] = useLocalStorage();
  const [filterParams, setFilterParams] = useSearchParams();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  const [justCopiedEmail, setJustCopiedEmail] = useState<boolean>(false);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const modalParam = filterParams.get("modal");
    if (modalParam === "support" && !isSupportModalOpen) {
      setIsSupportModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  useEffect(() => {
    if (isSupportModalOpen) {
      setIsOverlayOpen(true);
      setOverlayContent(<SupportModal />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSupportModalOpen]);

  useEffect(() => {
    if (isSupportModalOpen && !isOverlayOpen) {
      // Adds "closing" class to fade out modal
      setOverlayContent(<SupportModal classes="modal--closing" />);
      setTimeout(() => {
        setIsSupportModalOpen(false);
      }, OVERLAY_DELAY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOverlayOpen]);

  useEffect(() => {
    const parsedUserInfo: UserInfo | null =
      typeof localStorageValue === "string"
        ? JSON.parse(localStorageValue)
        : null;
    // if there IS auth keys, redirect to live tracking page.
    if (
      !blockRedirect &&
      parsedUserInfo?.access_key &&
      parsedUserInfo?.refresh_key
    ) {
      setIsOverlayOpen(false);
      setOverlayContent(null);

      getAdditionalUserInfoAndRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorageValue]);

  // fetches additional info such as name, customer, timezone, ect.
  const getAdditionalUserInfoAndRedirect = async () => {
    await fetchUserInfo()
      .then((resp) => {
        setUserInfo({
          ...userInfo,
          ...resp,
        });
        // Redirect to whichever homepage the user's role dictates
        if (checkIsStaff(resp.admin_user)) {
          const parsedUserInfo: UserInfo | null =
            typeof localStorageValue === "string"
              ? JSON.parse(localStorageValue)
              : null;
          if (
            parsedUserInfo &&
            parsedUserInfo?.access_key &&
            parsedUserInfo?.refresh_key
          ) {
            // TODO: Remove this once Ops Portal is integrated into a single app.
            // Ensure ops portal keys are set.
            localStorage.setItem("access_token", parsedUserInfo?.access_key);
            localStorage.setItem("refresh_token", parsedUserInfo?.refresh_key);
          }

          window.location.href = "/ops/trips/manage";
        } else {
          navigate("/live-tracking/trips?day=today");
        }
      })
      .catch(() => {
        console.error(
          "Something went wrong when fetching additional user info."
        );
      });
  };

  useEffect(() => {
    if (justCopiedEmail) {
      // "Email copied to clipboard!" text stays on the screen for the duration of this setTimeout
      setTimeout(() => {
        setJustCopiedEmail(false);
      }, 1500);
    }
  }, [justCopiedEmail]);

  return (
    <div className={classNames("signed-out-template", classes)}>
      {isLanding && (
        <div className="signed-out-template__landing-background"></div>
      )}
      <div
        className={classNames("signed-out-template__content", {
          "signed-out-template__content--landing-page": isLanding,
        })}
        // removes main page from tab index when mobile side nav or other drawer/modal is open
        {...{ inert: isOverlayOpen ? "" : undefined }}
      >
        {children}
      </div>
      <Overlay
        isActive={isOverlayOpen}
        onClick={() => {
          setIsOverlayOpen(false);
          filterParams.delete("modal");
          setFilterParams(filterParams);
        }}
        srText="Close menu"
        content={overlayContent}
      />
    </div>
  );
}
