import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isYesterday from "dayjs/plugin/isYesterday";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/en";

import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(relativeTime);

dayjs.locale("en");
export {
  dayjs,
  Dayjs,
  utc,
  timezone,
  localizedFormat,
  advancedFormat,
  customParseFormat,
  isBetween,
  isYesterday,
  isToday,
  isTomorrow,
  relativeTime,
};

export const getTimezoneText = (timezone?: string) => {
  if (!timezone) {
    return "";
  } else {
    return new Date()
      .toLocaleTimeString("en-us", {
        timeZone: timezone || "UTC",
        timeZoneName: "short",
      })
      .split(" ")[2];
  }
};

export const getTimezoneCorrectedDate = (
  date: string,
  tz: string | undefined
) => {
  return dayjs(date).tz(tz || "UTC");
};

export const isDayAfter = (
  start_date: string,
  compared_date: string,
  tz: string | undefined
) => {
  return !dayjs(getTimezoneCorrectedDate(start_date, tz)).isSame(
    getTimezoneCorrectedDate(compared_date, tz),
    "day"
  );
};
